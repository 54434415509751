import Ventilation from '@core/data/generated/Ventilation.json';
import { NameValueMap } from '@core/src/types/KeyValueMap';
import { VentilationType } from '@core/src/classes/Project';
// import Ventilation_deCH from '@api/data/Ventilation_de-CH.json';

export interface VentilationImportType extends Omit<VentilationType, 'apartmentType'> {
  apartmentType: string;
}

export default {
  Ventilation,
  // Ventilation_deCH
} as NameValueMap<VentilationImportType[]>;
