export const getDateString = (timestamp: Date): string => {
  const lastModified = new Date(timestamp);
  const day = String(lastModified.getDate()).padStart(2, '0');
  const month = String(lastModified.getMonth() + 1).padStart(2, '0');
  const year = lastModified.getFullYear();
  return `${day}-${month}-${year}`;
};

export const getFileFromUrl = async (url: string, fileName: string) => {
  const imageFetch = await fetch(url).catch(() => Promise.reject(Error('File not downloaded')));
  const imageBlob = await imageFetch.blob().catch(() => Promise.reject(Error('Could not create file blob')));
  return new File([imageBlob], fileName);
};
