import React, { createContext, useContext, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { ProjectReportSettingsType, ProjectType } from '@core/src/classes/Project';
import { ApiRequestConfig } from '@core/src/types/api-requests/ApiRequestConfig';
import useUser from '@frontend/context/User';
import useApplication from '@frontend/context/Application';
import { ProjectDatalayer } from '@frontend/services/useDatalayer';
import { getDateString, getFileFromUrl } from '@frontend/services/utilities';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { saveAs } from 'file-saver';
import useProjectLocalService from '@frontend/services/useProjectLocalService';
import { useHistory } from 'react-router-dom';
import useRouteBuilder from '@frontend/services/useRouteBuilder';
import useOverlay from './LoadingOverlay';
import useNotifications from '../services/useNotifications';

export const TRANSFER_LOCAL_PROJECT_KEY = 'transferProject';
export const TRANSFER_LOCAL_PROJECT_NEW_KEY = 'transferProjectNewKey';
export const REDIRECT_AFTER_PROJECT_TRANSFER = 'redirectAfterProjectTransfer';
export const TRANSFER_LOCAL_PROJECT_DONE = 'transferProjectDone';

export interface ProjectProviderProps {
  children?: React.ReactNode;
  datalayer: ProjectDatalayer;
}

export interface ProjectServiceProvider {
  projects: ProjectType[];
  currentProject: ProjectType | null;
  getProjectSkeleton: () => ProjectType;
  setCurrentProject: (projectId: string) => boolean;
  addProject: (projectData: ProjectType, projectImage: File | null) => Promise<void>;
  cloneProject: (projectData: ProjectType) => Promise<void>;
  updateProject: (projectId: string, projectData: Partial<ProjectType>, lazyUpdate?: boolean, projectImage?: File | null, deleteImage?: boolean) => Promise<void>;
  removeProject: (projectId: string) => void;
  downloadProjectReport: (project: ProjectType, projectReportUrl: string) => void;
  createProjectReport: (projectData: ProjectType, reportSettings: ProjectReportSettingsType) => Promise<void>;
}

export interface ProjectDatalayerProps {
  callApi: (fullApiInfo: ApiRequestConfig) => Promise<any>;
}

const ProjectContext = createContext<ProjectServiceProvider>({
  projects: [],
  currentProjectId: null,
  currentProject: null,
} as unknown as ProjectServiceProvider);

const ProjectProvider = ({ children, datalayer }: ProjectProviderProps) => {
  const { getTranslation, locale } = useApplication();
  const { currentUser } = useUser();
  const { setOverlayMessage } = useOverlay();
  const { showNotification } = useNotifications();
  const LocalStorageService = useProjectLocalService();
  const history = useHistory();
  const { buildProjectRoute } = useRouteBuilder();
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
  const [contextLoaded, setContextLoaded] = useState<boolean>(false);

  const getProjectSkeleton = (): ProjectType => {
    const projectSkeleton = {
      createDate: new Date(),
      uid: uuidv4(),
      locale: locale.toLowerCase(),
      details: {
        title: '',
        zip: '',
        location: '',
        imageUrl: undefined,
      },
      lastModified: new Date(),
    };
    return projectSkeleton;
  };

  const setCurrentProject = (projectId: string): boolean => {
    if (!contextLoaded) {
      console.log('setCurrentProject:ContextNotReady');
      return false;
    }
    // not directly exposing the setter by choice
    if (projects.findIndex((p) => p.uid === projectId) !== -1) {
      setCurrentProjectId(projectId);
      return true;
    }
    // project not found
    console.log('setCurrentProject: invalid projectId', projectId);
    return false;
  };

  const addProject = async (project: ProjectType, projectImage: File | null) => {
    setOverlayMessage(getTranslation('LabelLoadingCreatingProject'));
    const newProject = await trackPromise(datalayer.addProject(project, projectImage))
      .catch((error) => {
        // TODO: Generally handle errors
        showNotification(getTranslation('TextErrorDefaultAPI'));
        console.log('addProjectError', error);
        return error;
      });
    await setProjects([...projects, newProject]);
  };

  const transferProjectFromLocalStorageToDB = async (uid: string) => {
    const project = await LocalStorageService.getProject(uid)
      .catch(() => Promise.reject(Error('Could not get project from localStorage')));
    const clonedProject = cloneDeep(project);
    let projectImage: File | null = null;
    if (clonedProject) {
      if (clonedProject.details?.imageUrl) {
        const imagePath = clonedProject.details.imageUrl.replace('_sm.', '.');
        delete clonedProject?.details.imageUrl;
        projectImage = await getFileFromUrl(imagePath, 'projectImage')
          .catch(() => Promise.reject(Error('Could not download file')));
      }

      // Project needs new uid, save it to localstore to use later
      clonedProject.uid = uuidv4();
      localStorage.setItem(TRANSFER_LOCAL_PROJECT_NEW_KEY, clonedProject.uid);
      addProject(clonedProject, projectImage)
        .then(() => LocalStorageService.removeProject(uid))
        .catch(() => Promise.reject(Error('Could not add project to DB')));
    }
  };

  const cloneProject = async (project: ProjectType) => {
    setOverlayMessage(getTranslation('LabelLoadingCreatingProject'));
    const projectClone = cloneDeep(project);
    delete projectClone._id;
    projectClone.uid = uuidv4();
    delete projectClone.details.imageUrl;
    delete projectClone.lastReport;
    projectClone.createDate = new Date();
    projectClone.lastModified = new Date();
    projectClone.details.title = `${getTranslation('LabelCopyOf')} ${projectClone.details.title}`;

    const newProject = await trackPromise(datalayer.addProject(projectClone, null))
      .catch((error) => {
        // TODO: Generally handle errors
        showNotification(getTranslation('TextErrorDefaultAPI'));
        console.log('addProjectError', error);
        return error;
      });
    await setProjects([...projects, newProject]);
  };

  const updateProject = async (projectId: string, projectData: Partial<ProjectType>, lazyUpdate = true, projectImage?: File|null, deleteImage?: boolean) => {
    projectData.locale = locale.toLowerCase(); // locale will be rewritten on update => Bug#195953
    projectData.lastModified = new Date();
    projectData.lastReport = null;
    if (lazyUpdate) {
      // return before completion
      const fullProjectData = { ...projects.find((p) => p.uid === projectId), ...projectData } as ProjectType;
      datalayer.updateProject(projectId, projectData, (projectImage) || null, (deleteImage) || false)
        .catch((error) => {
          showNotification(getTranslation('TextErrorUpdateProject'));
          console.log('updateProjectError', error);
        });
      if (fullProjectData) {
        setProjects(projects.map((p) => (p.uid === projectId ? fullProjectData : p)));
      }
    } else {
      // return after datalayer completion
      return trackPromise(datalayer.updateProject(projectId, projectData, (projectImage) || null, (deleteImage) || false)
        .then((updatedProjectData) => {
          setProjects(projects.map((p) => (p.uid === projectId ? updatedProjectData : p)));
        })
        .catch((error) => {
          showNotification(getTranslation('TextErrorUpdateProject'));
          console.log('updateProjectError', error);
        }));
    }
    return Promise.resolve();
  };

  const removeProject = async (projectId: string) => datalayer.removeProject(projectId)
    .then(() => {
      setProjects(projects.filter((p) => p.uid !== projectId));
    })
    .catch((error) => {
      // TODO: Generally handle errors
      showNotification(getTranslation('TextErrorDefaultAPI'));
      return error;
    });

  const getCurrentProject = () => projects.find((project) => project.uid === currentProjectId) || null;

  /* const getProjectReportLocalUrl = async (reportUrl: string): Promise<string> => {
    const reportRequest = new Request(reportUrl);
    fetch(reportRequest)
      .then((response) => response.blob())
      .then((reportBlob) => {
        const formattedBlob = reportBlob.slice(0, reportBlob.size, 'application/pdf');
        return URL.createObjectURL(formattedBlob);
      })
      .catch((error) => {
        console.error('fetchReportError', error);
        return '';
      });
  }; */

  const downloadProjectReport = (project: ProjectType, projectReportUrl: string) => {
    // open url in new window
    if (projectReportUrl && projectReportUrl !== '') {
      const reportRequest = new Request(projectReportUrl);
      fetch(reportRequest)
        .then((response) => response.blob())
        .then((reportBlob) => {
          const formattedBlob = reportBlob.slice(0, reportBlob.size, 'application/pdf');
          saveAs(formattedBlob, `${project.details.title}_${getDateString(project.lastModified)}.pdf`);
        })
        .catch((error) => {
          console.error('fetchReportError', error);
          return '';
        });
      // const localReportUrl = await getProjectReportLocalUrl(projectReportUrl);
      // window.open(localReportUrl, '_blank');
    }
  };

  const createProjectReport = async (project: ProjectType, reportSettings: ProjectReportSettingsType) => {
    setOverlayMessage(getTranslation('LabelLoading'));
    project.locale = locale.toLowerCase(); // locale will be rewritten on update => Bug#195953
    const projectReportUrl = await trackPromise(datalayer.createProjectReport(project, reportSettings))
      .catch((error) => {
        // TODO: Generally handle errors
        showNotification(getTranslation('TextErrorDefaultAPI'));
        return error;
      });

    downloadProjectReport(project, projectReportUrl);
  };

  useEffect(() => {
    // Check if a project should be transfered from localStorage to DB
    const transferProjectKey = localStorage.getItem(TRANSFER_LOCAL_PROJECT_KEY);
    if (transferProjectKey && currentUser) {
      localStorage.removeItem(TRANSFER_LOCAL_PROJECT_KEY);
      transferProjectFromLocalStorageToDB(transferProjectKey)
        .then(() => {
          datalayer.getProjects().then((loadedProjects) => {
            // Projects from the same country are interchangeable unaware of which language was used to create them => Bug#195953
            const currentCountry = locale.toLowerCase().split('-').pop();
            setProjects(loadedProjects.filter((project) => project.locale.toLowerCase().split('-').pop() === currentCountry));
            setContextLoaded(true);
            localStorage.setItem(TRANSFER_LOCAL_PROJECT_DONE, '1');
          });
        })
        .catch((e) => console.error(e));
    } else {
      datalayer.getProjects().then((loadedProjects) => {
        // Projects from the same country are interchangeable unaware of which language was used to create them => Bug#195953
        const currentCountry = locale.toLowerCase().split('-').pop();
        setProjects(loadedProjects.filter((project) => project.locale.toLowerCase().split('-').pop() === currentCountry));
        setContextLoaded(true);
      });
    }
  }, [datalayer, currentUser]);

  useEffect(() => {
    /** cleanup the transfer from localstorage to db, and redirect * */
    const redirectAfterProjectTransfer = localStorage.getItem(REDIRECT_AFTER_PROJECT_TRANSFER);
    const transferProjectDone = localStorage.getItem(TRANSFER_LOCAL_PROJECT_DONE);
    const transferProjectNewKey = localStorage.getItem(TRANSFER_LOCAL_PROJECT_NEW_KEY);

    if (projects.length > 0 && transferProjectDone === '1' && transferProjectNewKey) {
      setCurrentProjectId(transferProjectNewKey);
      localStorage.removeItem(REDIRECT_AFTER_PROJECT_TRANSFER);
      localStorage.removeItem(TRANSFER_LOCAL_PROJECT_DONE);
      localStorage.removeItem(TRANSFER_LOCAL_PROJECT_NEW_KEY);

      // Everything done, do redirect if needed
      if (redirectAfterProjectTransfer) {
        history.push(buildProjectRoute('pdfexport', transferProjectNewKey));
      }
    }
  }, [contextLoaded, projects]);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        currentProject: getCurrentProject(),
        getProjectSkeleton,
        setCurrentProject,
        addProject,
        cloneProject,
        updateProject,
        removeProject,
        downloadProjectReport,
        createProjectReport,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

const useProject = () => useContext(ProjectContext);

export default useProject;
export { ProjectProvider, ProjectContext };
