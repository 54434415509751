import { ProjectRouteName, RouteName } from '@frontend/routes';
import useApplication from '@frontend/context/Application';

const useRouteBuilder = () => {
  const { locale, useLocaleRoutes } = useApplication();

  const getOverviewRoute = (useLocaleRoutes) ? `/${locale}/projects` : '/projects';

  const buildRoute = (target: RouteName) => {
    if (useLocaleRoutes) {
      return `/${locale}/${target}`;
    }
    return `/${target}`;
  };

  const buildProjectRoute = (target: ProjectRouteName, projectId?: string) => {
    if (useLocaleRoutes) {
      return (projectId ? `/${locale}/project/${projectId}/${target}` : getOverviewRoute);
    }
    return (projectId ? `/project/${projectId}/${target}` : getOverviewRoute);
  };

  return {
    buildRoute,
    buildProjectRoute,
  };
};

export default useRouteBuilder;
