import ArticleInfos from '@core/data/generated/ArticleInfos.json';
import { NameValueMap } from '@core/src/types/KeyValueMap';
import { ApartmentObjectType, ObjectArticleType } from '@core/src/classes/Project';
// import ArticleInfos_deCH from '@api/data/ArticleInfos_de-CH.json';

export interface ApartmentObjectImportType extends Omit<ApartmentObjectType, 'quantity'> {
  articles: ObjectArticleType[];
}

export default {
  ArticleInfos,
  // ArticleInfos_deCH
} as NameValueMap<ApartmentObjectImportType[]>;
