import { ProjectReportSettingsType, ProjectType } from '@core/src/classes/Project';
import authenticationService from './authentication.service';
import ProjectDbService from './useProjectDbService';
import ProjectLocalService from './useProjectLocalService';

export interface ProjectDatalayer {
  getProject: (projectId: string) => Promise<ProjectType | null>;
  getProjects: () => Promise<ProjectType[]>;
  addProject: (projectData: ProjectType, projectImage: File|null) => Promise<ProjectType>;
  removeProject: (projectId: string) => Promise<boolean>;
  updateProject: (projectId: string, projectData: Partial<ProjectType>, projectImage: File|null, deleteImage: boolean) => Promise<ProjectType>;
  createProjectReport: (projectData: ProjectType, settings: ProjectReportSettingsType) => Promise<string>;
}

const useDatalayer = (): ProjectDatalayer => {
  if (authenticationService.hasTokenLogin()) {
    return ProjectDbService();
  }
  return ProjectLocalService();
};

export default useDatalayer;
