import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { UserType } from '@core/src/types/UserType';
import authenticationService from '../services/authentication.service';

export interface UserProviderProps {
  children?: React.ReactNode;
}

export interface UserServiceProvider {
  currentUser: UserType | null;
  onLogin: (user: UserType, afterLogin?: () => void) => void;
  onLogout: () => void;
}

const UserContext = createContext<UserServiceProvider>({
  currentUser: null,
} as UserServiceProvider);

const UserProvider = ({ children }: UserProviderProps) => {
  const [currentUser, setCurrentUser] = useState<UserServiceProvider['currentUser']>(null);

  useEffect(() => {
    setCurrentUser(authenticationService.getUserFromToken());
  }, []);

  const onLogout = () => {
    setCurrentUser(null);
    authenticationService.clearTokenLogin();
    window.location.href = '/'; // TODO useHistory not working here, any better solutions? Also needs locale
  };

  const onLogin = async (user: UserType, afterLogin?: () => void) => {
    authenticationService.setLoginToken({
      ...user,
      ...{
        tokenCreateDate: new Date(),
      },
    });
    setCurrentUser(user);

    if (afterLogin) {
      afterLogin();
    } else {
      window.location.reload(); // TODO useHistory not working here, any better solutions?
    }
  };

  return (
    <UserContext.Provider
      value={{
        currentUser, onLogin, onLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export default useUser;
export { UserProvider };
