import ApartmentDefaults from '@core/src/imports/ApartmentDefaultsImports';
import ArticleInfos from '@core/src/imports/ArticleInfosImports';
import Ventilation from '@core/src/imports/Ventilation';

const DataService = () => {

  const getApartmentDefaults = (locale?: string) => {
    if (locale && ApartmentDefaults['ApartmentDefaults_'+locale]) {
      return ApartmentDefaults['ApartmentDefaults_'+locale];
    }
    return ApartmentDefaults['ApartmentDefaults'];
  };

  const getArticleInfos = (locale?: string) => {
    if (locale && ArticleInfos['ArticleInfos_'+locale]) {
      return ArticleInfos['ArticleInfos_'+locale];
    }
    return ArticleInfos['ArticleInfos'];
  };

  const getVentilation = (locale?: string) => {
    if (locale && Ventilation['Ventilation_'+locale]) {
      return Ventilation['Ventilation_'+locale];
    }
    return Ventilation['Ventilation'];
  };

  return {
    getApartmentDefaults,
    getArticleInfos,
    getVentilation
  };

};

const dataService = DataService();
export default dataService;
