import { NOTIFICATION_TYPE, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import React from 'react';
import { NotificationItem } from '../components/basic/NotificationItem';

const useNotifications = () => {
  const showNotification = (text: string, type: NOTIFICATION_TYPE = 'danger') => {
    Store.addNotification({
      type,
      insert: 'top',
      container: 'bottom-left',
      width: 400,
      animationIn: ['animate__animated animate__fadeInLeft animate__faster'], // `animate.css v4` classes
      animationOut: ['animate__animated animate__fadeOut animate__faster'], // `animate.css v4` classes
      content: <NotificationItem appearance="danger">{text}</NotificationItem>,
    });
  };
  return {
    showNotification,
  };
};

export default useNotifications;
