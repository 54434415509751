/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import useApplication from '@frontend/context/Application';
import geberitLogo from '../images/geberit_logo.svg';

function Footer() {
  const { getTranslation } = useApplication();
  const copyrightText = `© ${new Date().getFullYear()} ${getTranslation('LabelCompanyName')}`;

  const handleCookieSettings = () => {
    // @ts-ignore
    if (window?.UC_UI) {
      // @ts-ignore
      window?.UC_UI.showSecondLayer();
    }
  };

  return (
    <footer className="c-footer footer-container">
      <div className="container">
        <div className="c-footer__wrapper">
          <div className="footer-logo">
            <img src={geberitLogo} title="GEBERIT" alt="GEBERIT" className={`header-logo ${isMobileOnly ? 'mobile' : ''}`} />
          </div>
          <div className="footer-content c-footer__nav">
            <p className="footer-copyright">{copyrightText}</p>
            <a className="footer-link" target="_blank" href={getTranslation('LinkFooterImpressum')}>{getTranslation('LabelFooter_impressum')}</a>
            <a className="footer-link" target="_blank" href={getTranslation('LinkFooterDisclaimer')}>{getTranslation('LabelFooter_disclaimer')}</a>
            <a className="footer-link" target="_blank" href={getTranslation('LinkFooterDataPrivacy')}>{getTranslation('LabelFooter_data-privacy')}</a>
            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */ }
            <a className="footer-link" onClick={() => handleCookieSettings()}>{getTranslation('LabelFooter_cookie-consent')}</a>
            <a className="footer-link" target="_blank" href={getTranslation('LinkFooterEuDeclarations')}>{getTranslation('LabelFooter_eu-declarations')}</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
