import { ProjectReportSettingsType, ProjectType } from '@core/src/classes/Project';
import useApiRequestService from '@frontend/services/useApiRequestService';
import useUser from '@frontend/context/User';
import { ProjectDatalayer } from '@frontend/services/useDatalayer';
import ApiRequestBuilder from '../requests/api-request.builder';
import { BACKEND_API_ENDPOINTS } from '../requests/api-endpoints-list';

const useProjectDbService = (): ProjectDatalayer => {
  const { callApi } = useApiRequestService();
  const { currentUser } = useUser();

  const getProjects = async (): Promise<ProjectType[]> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.getUserProjects, { userId: currentUser.geberitId })
        .setToken(currentUser.accessToken)
        .build();
      return callApi(apiConfig)
        .then((projects) => projects)
        .catch((err) => {
          console.error('Error DB:getProjects', err);
          return Promise.reject(err);
        });
    }
    return [];
  };

  const getProject = async (projectId: string): Promise<ProjectType|null> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.getProject, { userId: currentUser.geberitId, projectId })
        .setToken(currentUser.accessToken)
        .build();
      return callApi(apiConfig)
        .then((project) => project)
        .catch((err) => {
          console.error('Error DB:getProjects', err);
          return Promise.reject(err);
        });
    }
    return null;
  };

  const addProject = async (projectData: ProjectType, projectImage: File|null): Promise<ProjectType> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.createProject, { userId: currentUser.geberitId })
        .setBody({ projectData, projectImage })
        .setFileFields(['projectImage'])
        .setToken(currentUser.accessToken)
        .build();
      return callApi(apiConfig)
        .then((savedProject) => savedProject)
        .catch((err) => Promise.reject(err));
    }
    return Promise.reject(new Error('Error DB:addProject - currentUser undefined'));
  };

  const updateProject = async (projectId: string, projectData: Partial<ProjectType>, projectImage: File|null, deleteImage: boolean): Promise<ProjectType> => {
    if (currentUser) {
      if (deleteImage && projectData.details) {
        projectData.details.imageUrl = 'remove';
      }
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.updateProject, { userId: currentUser.geberitId, projectId })
        .setBody({ projectData, projectImage })
        .setFileFields(['projectImage'])
        .setToken(currentUser.accessToken)
        .build();
      return callApi(apiConfig)
        .then((updatedProject) => updatedProject)
        .catch((err) => Promise.reject(err));
    }
    return Promise.reject(new Error('Error DB:updateProject - currentUser undefined'));
  };

  const removeProject = async (projectId: string): Promise<boolean> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.deleteProject, { userId: currentUser.geberitId, projectId })
        .setToken(currentUser.accessToken)
        .build();
      return callApi(apiConfig)
        .catch((err) => Promise.reject(err));
    }
    return false;
  };

  const createProjectReport = async (projectData: ProjectType, reportSettings: ProjectReportSettingsType): Promise<string> => {
    if (currentUser && projectData) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.createProjectReport, { userId: currentUser.geberitId, projectId: projectData.uid })
        .setBody({ projectData, reportSettings })
        .setToken(currentUser.accessToken)
        .build();
      const projectReportUrl = await callApi(apiConfig);
      return projectReportUrl;
    }
    return Promise.reject();
  };

  return {
    getProjects,
    getProject,
    addProject,
    removeProject,
    updateProject,
    createProjectReport,
  };
};

export default useProjectDbService;
