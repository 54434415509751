import { ProjectReportSettingsType, ProjectType } from '@core/src/classes/Project';
import ApiRequestBuilder from '@frontend/requests/api-request.builder';
import { BACKEND_API_ENDPOINTS } from '@frontend/requests/api-endpoints-list';
import useApiRequestService from '@frontend/services/useApiRequestService';
import { ProjectDatalayer } from '@frontend/services/useDatalayer';

const useProjectLocalService = (): ProjectDatalayer => {
  const { callApi } = useApiRequestService();

  const getProjectsFromProjectStore = (): ProjectType[] => {
    const localProjects = localStorage.getItem('projects');
    if (localProjects) {
      return JSON.parse(localProjects);
    }
    return [];
  };

  const updateProjectStore = (projects: ProjectType[]) => {
    localStorage.setItem('projects', JSON.stringify(projects));
  };

  const getProjects = async () => getProjectsFromProjectStore();

  const getProject = async (projectId: string): Promise<ProjectType | null> => getProjectsFromProjectStore().find((project) => project.uid === projectId) || null;

  const addProject = async (project: ProjectType, projectImage: File | null): Promise<ProjectType> => {
    if (projectImage) {
      // upload project image to blob storage
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.uploadAnonymousProjectImage, { projectId: project.uid })
        .setBody({ projectImage })
        .setFileFields(['projectImage'])
        .build();
      project.details.imageUrl = await callApi(apiConfig).then((response) => response.data);
    }
    const allProjects = getProjectsFromProjectStore();
    allProjects.push(project);
    updateProjectStore(allProjects);
    return project;
  };

  const updateProject = async (projectId: string, projectData: Partial<ProjectType>, projectImage: File | null, deleteImage: boolean): Promise<ProjectType> => {
    const allProjects = getProjectsFromProjectStore();
    const storedProject = allProjects.find((p) => p.uid === projectId);
    if (storedProject) {
      const updatedProject = { ...storedProject, ...projectData };
      if (projectImage) {
        // upload project image to blob storage
        const apiConfig = new ApiRequestBuilder()
          .setEndpoint(BACKEND_API_ENDPOINTS.uploadAnonymousProjectImage, { projectId })
          .setBody({ projectImage })
          .setFileFields(['projectImage'])
          .build();
        updatedProject.details.imageUrl = await callApi(apiConfig).then((response) => response.data);
      }
      // delete old image
      if (deleteImage && updatedProject.details.imageUrl) {
        // delete project image from blob storage
        const filename = updatedProject.details.imageUrl.split('?')[0].split('/').pop() || '';
        const apiConfig = new ApiRequestBuilder()
          .setEndpoint(BACKEND_API_ENDPOINTS.deleteAnonymousProjectImage, { filename })
          .build();
        await callApi(apiConfig);
        updatedProject.details.imageUrl = '';
      }
      updateProjectStore(allProjects.map((p) => (p.uid === projectId ? updatedProject : p)));
      return updatedProject;
    }
    return Promise.reject(new Error('Error LOCAL:updateProject - project not found'));
  };

  const removeProject = async (projectId: string): Promise<boolean> => {
    // delete project image on blob storage
    const project = await getProject(projectId);
    if (project && project.details.imageUrl) {
      const filename = project.details.imageUrl.split('?')[0].split('/').pop() || '';
      if (filename) {
        const apiConfig = new ApiRequestBuilder()
          .setEndpoint(BACKEND_API_ENDPOINTS.deleteAnonymousProjectImage, { filename })
          .build();
        await callApi(apiConfig);
      }
    }
    updateProjectStore(getProjectsFromProjectStore().filter((thisProject) => thisProject.uid !== projectId));
    return true;
  };

  const createProjectReport = async (projectData: ProjectType, reportSettings: ProjectReportSettingsType): Promise<string> => {
    if (projectData) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.createAnonymousProjectReport, { projectId: projectData.uid })
        .setBody({ projectData, reportSettings })
        .build();
      const projectReportUrl = await callApi(apiConfig);
      if (projectReportUrl && projectReportUrl !== '') {
        const updateData: Partial<ProjectType> = {
          lastReport: {
            createdAt: new Date(),
            report: {
              pdfUrl: projectReportUrl,
            },
            isObsolete: false,
          },
        };
        await updateProject(projectData.uid, updateData, null, false);
      }
      return projectReportUrl;
    }
    return Promise.reject();
  };

  return {
    getProject,
    getProjects,
    addProject,
    updateProject,
    removeProject,
    createProjectReport,
  };
};

export default useProjectLocalService;
