import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { UrlParamType } from '@core/src/types/GlobalTypes';
import LoadingOverlay from '@frontend/components/layout/LoadingOverlay';
import { getLocaleConfigByDomain } from '@core/src/services/language.service';

import TranslationService from '@core/src/services/translation.service';
import DataService from '@core/src/services/data.service';
import { ApartmentDefaultsType, ApartmentObjectType, ObjectArticleType, ObjectInfoType, VentilationType } from '@core/src/classes/Project';
import { NameValueMap } from '@core/src/types/KeyValueMap';

export interface ApplicationProviderService {
  getTranslation: (key: string) => string,
  apartmentDefaults: ApartmentDefaultsType[]|null,
  articleInfoDefaults: NameValueMap<ObjectArticleType[]>|null;
  apartmentDefaultObjects: ApartmentObjectType[]|null;
  ventilationSystems: VentilationType[]|undefined,
  locale: string,
  useLocaleRoutes: boolean
}

export interface ApplicationProviderProps {
  children?: React.ReactNode;
}

const ApplicationContext = createContext<ApplicationProviderService>({} as ApplicationProviderService);

const ApplicationProvider = ({ children }: ApplicationProviderProps) => {
  const { urlLocale } = useParams<UrlParamType>();
  const [useLocaleRoutes, setUseLocaleRoutes] = useState<boolean>(false);
  const locale = urlLocale ?? getLocaleConfigByDomain(window.location.hostname) ?? 'de-CH';

  const getTranslation = (key: string): string => TranslationService.getTranslation(key, locale);
  const apartmentDefaults = () => DataService.getApartmentDefaults(locale);
  const articleInfoDefaults = (): {apartmentDefaultObjects: ApartmentObjectType[], articleInfoDefaults: NameValueMap<ObjectArticleType[]>} => {
    const articleInfosData = DataService.getArticleInfos(locale) as ObjectInfoType[];
    const articleInfoForDefaults: NameValueMap<ObjectArticleType[]> = {};
    const apartmentObjects: ApartmentObjectType[] = articleInfosData.map((obj) => {
      const { articles, ...apartmentObjectDefault } = obj; // separating articles
      articleInfoForDefaults[obj.type] = articles;
      return apartmentObjectDefault;
    });

    return {
      apartmentDefaultObjects: apartmentObjects,
      articleInfoDefaults: articleInfoForDefaults,
    };
  };
  const ventilationSystems = () => DataService.getVentilation(locale);

  useEffect(() => {
    if (urlLocale) {
      setUseLocaleRoutes(true);
    }
  }, []);

  useEffect(() => {
    if (urlLocale) {
      setUseLocaleRoutes(true);
    }
  }, [urlLocale]);

  return (
    <ApplicationContext.Provider
      value={{
        getTranslation,
        apartmentDefaults: apartmentDefaults(),
        articleInfoDefaults: articleInfoDefaults().articleInfoDefaults,
        apartmentDefaultObjects: articleInfoDefaults().apartmentDefaultObjects,
        ventilationSystems: ventilationSystems(),
        locale,
        useLocaleRoutes,
      }}
    >
      <LoadingOverlay area="apartmentData" />
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplication = () => useContext(ApplicationContext);

export default useApplication;
export { ApplicationProvider, ApplicationContext };
