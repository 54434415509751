import { ApiRequestConfigInfoMap } from '@core/src/types/api-requests/ApiRequestConfigInfo';

// TODO: Check if needed or refactor
export const BACKEND_API_ENDPOINTS: ApiRequestConfigInfoMap = {
  gigyaScript: {
    endpoint: '/api/gigya/{locale}/{gigyaEnvironment}',
    method: 'GET',
    sendToken: false,
  },
  login: {
    endpoint: '/api/gigya/login',
    method: 'POST',
    sendToken: false,
  },
  getAllTranslations: {
    endpoint: '/api/data/translations',
    method: 'GET',
    sendToken: false,
  },
  getApartmentDefaults: {
    endpoint: '/api/data/apartment-defaults/{locale}',
    method: 'GET',
    sendToken: false,
  },
  getArticleInfos: {
    endpoint: '/api/data/article-infos/{locale}',
    method: 'GET',
    sendToken: false,
  },
  getVentilation: {
    endpoint: '/api/data/ventilation/{locale}',
    method: 'GET',
    sendToken: false,
  },
  getUserProjects: {
    endpoint: '/api/users/{userId}/projects',
    method: 'GET',
    sendToken: true,
  },
  getProject: {
    endpoint: '/api/users/{userId}/project/{projectId}',
    method: 'GET',
    sendToken: true,
  },
  createProject: {
    endpoint: '/api/users/{userId}/project',
    method: 'POST',
    sendToken: true,
    sendFiles: true,
  },
  updateProject: {
    endpoint: '/api/users/{userId}/project/{projectId}',
    method: 'PUT',
    sendToken: true,
    sendFiles: true,
  },
  deleteProject: {
    endpoint: '/api/users/{userId}/project/{projectId}',
    method: 'DELETE',
    sendToken: true,
  },
  createProjectReport: {
    endpoint: '/api/users/{userId}/report/{projectId}',
    method: 'POST',
    sendToken: true,
  },
  uploadAnonymousProjectImage: {
    endpoint: '/api/data/anonymous-image/{projectId}',
    method: 'POST',
    sendToken: false,
    sendFiles: true,
  },
  deleteAnonymousProjectImage: {
    endpoint: '/api/data/anonymous-image/{filename}',
    method: 'DELETE',
  },
  createAnonymousProjectReport: {
    endpoint: '/api/data/anonymous-report/{projectId}',
    method: 'POST',
  },
  deleteAnonymousProjectReport: {
    endpoint: '/api/data/anonymous-report/{filename}',
    method: 'DELETE',
  },
};
