import React, { useEffect, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import TagManager from 'react-gtm-module';

import {
  BrowserRouter, Route, Switch, Redirect, useLocation,
} from 'react-router-dom';

// style imports
import './styles/bootstrap_loader.scss';
import './styles/styles.geberit.web20.min.css';
import './styles/styles_common.css';
import './styles/global.scss';

import LoadingOverlay from '@frontend/components/layout/LoadingOverlay';
import { ReactNotifications } from 'react-notifications-component';
import { ApplicationProvider } from '@frontend/context/Application';
import { urlLocaleRegex } from '@core/src/services/language.service';
import { UserProvider } from './context/User';
import Footer from './layout/Footer';
import Header from './layout/Header';
import { ProjectProvider } from './context/Project';
import authenticationService from './services/authentication.service';
import { LoadingOverlayProvider } from './context/LoadingOverlay';
import useDatalayer from './services/useDatalayer';

// config Google Tag Manager
if (process.env.REACT_APP_GTMID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTMID });
}

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const BaseLayout = React.lazy(() => import('./layout/BaseLayout'));
const Page404 = React.lazy(() => import('./views/Error404'));
const Page500 = React.lazy(() => import('./views/Error500'));
// const Home = React.lazy(() => import('./views/Home'));
const Profile = React.lazy(() => import('./views/Profile'));

interface BaseComponentWrapperProps {
  children?: React.ReactNode
}

const BaseComponentWrapper = ({ children }: BaseComponentWrapperProps) => {
  const { pathname } = useLocation();
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <LoadingOverlayProvider>
      <ApplicationProvider>
        <ReactNotifications />
        <ProjectProvider datalayer={useDatalayer()}>
          <Header />
          <LoadingOverlay />
          <div className={`main-container ${isMobileOnly ? 'mobile' : ''}`}>
            <div id="top" ref={containerRef} />
            <div className="content-container">
              {children}
            </div>
            <Footer />
          </div>
        </ProjectProvider>
      </ApplicationProvider>
    </LoadingOverlayProvider>
  );
};

/* const UnauthenticatedRoute = ({ component: Component, ...rest }:any) => (
  <Route
    /!* eslint-disable react/jsx-props-no-spreading *!/
    {...rest}
    render={(props) => (
      !authenticationService.hasTokenLogin()
        ? (
          <BaseComponentWrapper>
            <Component {...props} />
          </BaseComponentWrapper>
        )
        : <Redirect to="/" />
    )}
  />
); */

const AuthenticatedRoute = ({ component: Component, ...rest }:any) => (
  <Route
    {...rest}
    render={(props) => (
      authenticationService.hasTokenLogin()
        ? (
          <BaseComponentWrapper>
            <Component {...props} />
          </BaseComponentWrapper>
        )
        : <Redirect to="/" />
    )}
  />
);

const HybridRoute = ({ component: Component, ...rest }:any) => (
  <Route
    {...rest}
    render={(props) => (
      <BaseComponentWrapper>
        <Component {...props} />
      </BaseComponentWrapper>
    )}
  />
);

export const NotFoundRoute = ({ ...rest }:any) => (
  <Route
    /* eslint-disable react/jsx-props-no-spreading */
    {...rest}
    render={(props) => ((props.match.params.urlLocale !== undefined)
      ? <Redirect to={`/${props.match.params.urlLocale}/404`} />
      : <Redirect to="/404" />
    )}
  />
);

const App = () => (
  <UserProvider>
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <HybridRoute exact path={`/:urlLocale${urlLocaleRegex}?/404`} name="Page 404" component={Page404} />
          <HybridRoute exact path={`/:urlLocale${urlLocaleRegex}?/500`} name="Page 500" component={Page500} />
          <AuthenticatedRoute path={`/:urlLocale${urlLocaleRegex}?/profile`} exact name="User Profile" component={Profile} />
          <HybridRoute path={`/:urlLocale${urlLocaleRegex}?/`} name="Content" component={BaseLayout} />
          <NotFoundRoute from="*" to={`/:urlLocale${urlLocaleRegex}?/404`} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  </UserProvider>
);

export default App;
